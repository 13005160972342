@import '../../node_modules/compass-mixins/lib/compass';

// Import libraries
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'shared/flat_ui_colors';
@import 'shared/custom_colors';
@import 'vendors/fontello';

@import 'main/index';
@import 'main/stars';
@import 'main/courses';
@import 'main/blog';
@import 'main/classes';

.site-header {
  background-color: $flat-midnight-blue;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

body,
h1,
h2,
h3,
h4,
p {
  color: $flat-midnight-blue;
  font-family: 'Lato', 'Source Sans Pro', Helvetica, sans-serif;
}

html,
body {
  height: 100%;
  position:relative;
  overflow-x: hidden;
}

a {
  &:hover {
    text-decoration: none;
  }
}

object {
  width: 100%;
}

// Home
.title-wrap {
  margin-top: 10%;

  h1 {
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-size: 5em;
    font-weight: 700;
  }
}

// Loading
.loading-app-bar {
  @include border-radius(10px);
  @include centerer(true, true);
}

.loading-app-text {
  margin-top: -40px;
  text-transform: uppercase;
}

// Header
.main-title {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-size: 3.5em;
  font-weight: 700;
  text-transform: uppercase;
}

.color-filter {
  @include background($flat-clouds);
  @include background-size(cover);
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  width: 100%;
}

.intro-head {
  height: 250px;
  position: absolute;
  width: 100%;
  z-index: 20;
}

.intro-header-bg {
  @include background(image-url('login/header-bg.png'));
  @include background-size(cover);
  background-position: center bottom;
  height: 250px;
  position: relative;
  width: 100%;
}

.separator-line {
  @include background($flat-peter-river);
  @include background-size(cover);
  height: 8px;
  width: 100%;
}

.vertical-horizontal-center {
  @include centerer(true, true);
}

// Login
.login-box {
  margin-top: 50px;

  .panel.panel-info {
    border: 1px solid $flat-peter-river;
    padding: 20px;
  }

  .panel-heading {
    border-bottom: 1px solid $flat-peter-river;
    padding-bottom: 10px;
  }

  .panel-title {
    color: $flat-midnight-blue;
    font-size: 20px;
  }

  .panel-body {
    padding-top: 30px;
  }

  .input-group {
    margin-bottom: 25px;
  }

  .form-group {
    margin-top: 10px;
  }

  .btn-login {
    font-size: 150%;
    width: 100%;
  }

  .login-footer {
    border-top: 1px solid $border-gray;
    font-size: 16px;
    padding-top: 15px;
  }

  .login-alert {
    font-size: 16px;
  }

}

// Control Panel
.class-info-pannel {
  margin: 10px 0;

  p {
    color: $flat-clouds;
    display: inline-block;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-size: 1.4em;
  }

  object,
  i {
    display: inline-block;
    padding-left: 20px;
  }

  .glyphicon-education {
    color: $flat-midnight-blue;
    font-size: 1.2em;
  }

  .fa-trophy {
    color: $flat-sun-flower;
    font-size: 1.2em;
  }

  .fa-sign-out {
    color: $flat-midnight-blue;
    font-size: 1.2em;
  }

  a {
    color: $flat-clouds;
  }
}

.class-info.wrapper {
  @include background-image(linear-gradient($flat-peter-river, shade($flat-peter-river, 25%)));
  @include border-bottom-radius(25px);
  // @include box-shadow(0 1px 4px 0 rgba($midnight-blue, .3),
  // 0 0 40px $shadow-1 inset);
  border-bottom: 1px solid $flat-belize-hole;
  // margin-top: -8px;
}

.lesson-list {
  color: $flat-midnight-blue;
  font-size: 2em;
  margin-left: 20px;

  .header {
    font-size: 1.3em;
    margin-top: 20px;
  }
}

.captcha-login {
  // background-color: rgba($flat-silver, .5);
  background-color: $bootstrap-gray-bg;
  // border: 1px solid hsla(204, 8%, 76%, .8);
  border: 1px solid $bootstrap-gray-border;
  margin-bottom: 0 !important;
  padding: 20px;
}

.btn-menu-separator {
  margin: 0 10px 0 0;
}

.li-menu-top {
  color: $bootstrap-black !important;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.code-padding {
  padding: 0 10px !important;
}

.badge-magenta {
  color: $flat-clouds;
  background-color: $magenta;
}