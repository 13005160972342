@import '../../../node_modules/compass-mixins/lib/compass';
@import '../shared/flat_ui_colors';
@import '../shared/custom_colors';

@import '../../../node_modules/compass-mixins/lib/compass';
@import '../shared/flat_ui_colors';
@import '../shared/custom_colors';

.classes {
  margin: 30px 12% 0;
}

.class-summary {
  margin: 30px 10% 0;

  h4 {
    font-weight: bold;
  }
}

.summary-icon {
  height: 50px;
  width: 50px;
  margin:  0 0 10px;
}

.separator-small {
  margin: 30px auto 0;
  width: 80%;
  height: 2px;
  background-color: rgba($flat-asbestos, .3);
}

.disqus-wrapper {
  margin: 30px 12% 0;
}

.point-icon {
  height: 30px;
  width: 30px;
  margin: 0 10px 0 0;
  display: inline;
}
