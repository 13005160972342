@import '../../../node_modules/compass-mixins/lib/compass';
@import '../shared/flat_ui_colors';
@import '../shared/custom_colors';

@mixin logo-bg($color1, $color2:false){
  @include border-radius(25px); 
  background-color: $color1;
  @if $color2 {
    @include background-image(radial-gradient(
      circle, $color1, $color2));
  }
  height: 45px;
  padding: 5px 8px;
  position: absolute;
  top: 8%;
  width: 45px;
}

.typed-display {
  color: $flat-clouds;
  font-family: 'Lato', sans-serif;
  font-size: 5.5vw;
  z-index: 20;
}

@media only screen and (max-width: 575px) {
  .typed-display {
    font-size: 60px;
  }
}

.particles-frame {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.main-cover {
  @include background-image(
    radial-gradient(
      $magenta 5%,
      $dark-blue 65%,
      $dark-blue 100%
    )
  );
  background-color: $fucia;
  color: $flat-clouds;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.relative {
  position: relative;
}

.intro-text {
  left: 0;
  padding-top: 30%;
  position: absolute;
  right: 0;

  h1 {
    color: $flat-clouds;
    font-family: 'Lato', sans-serif;
    font-size: 70px;
    z-index: 20;
  }

}

.blog-intro {
  font-size: 50px;
}

.capitalize {
  font-size: 30px;
}

.blog-intro-text {
  margin: 40px 0;
}

.bg-index {
  background-color: rgba($flat-clouds, .6);
}

.text-frame {
  position: relative;
}

.logo-navbar-magenta {
  @include logo-bg(rgba($magenta, .8));
  // @include logo-bg($magenta, $dark-blue);
}

.logo-navbar-clouds {
  @include logo-bg(rgba($flat-clouds, .8)); // @include logo-bg($magenta, $dark-blue);
}

.navbar-brand {
  margin: 0 60px 0 0;
}

.wave-transition {
  position: relative;

}

.ocean {
  height: 3%;
  width: 100%;
  position: absolute;
  bottom: 20%;
  left: 0;
}

.wave {
  @include transform(rotate(180deg), translate3d(0, 0, 0));
  background: url('/build/imgs/svg/wave.svg') repeat-x;
  position: absolute;
  bottom: -198px;
  width: 500%;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
}

.wave:nth-of-type(2) {
  animation: wave 15s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  opacity: 1;
}

.wave:nth-of-type(3) {
  animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.blog-listing-intro {
  margin: 0 12% 40px;
}

.blog {
  margin: 250px 12% 0;

  @media only screen and (max-width: 575px) {
    margin: 50px 12% 0;
  }

  p {
    font-size: 20px;
  }
}

.coming-soon {
  border: 1px solid $flat-concrete;
  margin: 0 0 100px;
  padding: 20px;

  h4 {
    color: $flat-asbestos;
    font-size: 22px;
  }
}

.bot {
  height: 50px;
  width: 50px;
  margin:  0 0 10px;
}

.card-img-attribution {
  color: rgba($flat-asbestos, .8);
  margin: -20px;
  position: absolute;
  right: 25px;
  // top: 230px;
  z-index: 100;
}

h3 {
  &.blog-post-title {
    background-color: $magenta;
    border-left: 5px solid $flat-asbestos;
    border-right: 5px solid $flat-asbestos;
    color: $flat-clouds;
    min-height: 85px;
    padding: 10px 15px;
  }
}

.timestamps {
  margin: 20px 0 5px;
}

.content-type {
  margin: 0 0 5px;
}

.blog-posts-list {
  margin: 0 0 40px 0;
}

.data-label {
  color: $flat-asbestos;
}

.data-info {
  color: rgba($flat-asbestos, .7);

  .tag {
    // border: 1px solid rgba($flat-asbestos, .5);
    padding: 2px;
  }
}

.card-excerpt {
  font-size: 20px;
  margin: 0 0 20px;
}