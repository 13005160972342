/**
 * Inspiration source: https://codepen.io/saransh/pen/BKJun
 */

@import '../../../node_modules/compass-mixins/lib/compass';

@function star-generator ($number){
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $number {
  $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }

  @return unquote($value);
}

// Original values
// $stars-small: star-generator(700);
// $stars-medium: star-generator(200);
// $stars-big: star-generator(100);

// Custom values
$stars-small: star-generator(400);
$stars-medium: star-generator(300);
$stars-big: star-generator(200);

$star-size-small: 1px;
$star-size-medium: 2px;
$star-size-big: 3px;

#stars-small {
  width: $star-size-small;
  height: $star-size-small;
  background: transparent;
  box-shadow: $stars-small;
  animation: animateStar 50s linear infinite;
  
  &:after {
    content: " ";
    position: absolute;
    top: 1000px;
    width: $star-size-small;
    height: $star-size-small;
    background: transparent;
    box-shadow: $stars-small;
  }
}
  
#stars-medium {
  width: $star-size-medium;
  height: $star-size-medium;
  background: transparent;
  box-shadow: $stars-medium;
  animation: animateStar 100s linear infinite;
  
  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: $star-size-medium;
    height: $star-size-medium;
    background: transparent;
    box-shadow: $stars-medium;
  }
}
  
#stars-big {
  width: $star-size-big;
  height: $star-size-big;
  background: transparent;
  box-shadow: $stars-big;
  animation: animateStar 150s linear infinite;
  
  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: $star-size-big;
    height: $star-size-big;
    background: transparent;
    box-shadow: $stars-big;
  }
}

@keyframes animateStar {

  from {
  transform: translateY(0px);
  }

  to {
  transform: translateY(-2000px);
  }
}
