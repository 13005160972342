@import '../../../node_modules/compass-mixins/lib/compass';
@import '../shared/flat_ui_colors';
@import '../shared/custom_colors';

$header-height: 300px;

.blog-post {
  padding: 90px auto 0;

  .blog-header-filter {
    background-color: rgba($flat-midnight-blue, .4);
    height: $header-height;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .blog-header-bg {
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 4px solid rgba($flat-silver, .5);
    height: $header-height;
    margin: 0 auto;
    width: 100%;
  }

  .img-attribution {
    color: rgba($flat-clouds, .5);
    position: absolute;
    right: 20px;
    top: $header-height - 25;
  }

  h1,
  h2,
  h3,
  p {
    font-family: 'Lato', sans-serif;
    color: $flat-midnight-blue;
  }

  h1 {
    // @include border-radius(10px);
    border-left: 15px rgba($flat-silver, .6) solid;
    border-right: 15px rgba($flat-silver, .6) solid;
    background-color: $magenta;
    color: $flat-clouds;
    font-size: 50px;
    padding: 10px 20px;
  }

  h2 {
    font-size: 35px;
  }

  li,
  p {
    font-size: 20px;
  }

  li,
  p {
    pre {
      font-size: 14px;
    }
  }

  .blog-title {
    font-size: 50px;
    font-family: 'Lato', sans-serif;
    margin: -($header-height/1.8) auto 0;
  }

  .blog-excerpt {
    margin: 25px 0;
  }

  .blog-slides {
    margin: 25px 0 0;
  }

  .blog-divider {
    margin: 0 auto 20px;
    padding: 0 0 10px;
    border-bottom: 1px solid $flat-silver;
  }

  .btn-slides {
    position: relative;

    .view-slides-icon {
      height: 30px;
      position: absolute;
      width: 30px;
    }

    .view-slides-text {
      margin: 0 20px 0;
    }

    .path {
      fill: $bootstrap-black;
    }

    &:hover {
      .path {
        fill: $white;
      }
    }

  }
}

.disqus-wrapper {

  margin: 30px 12% 0;
  
  .comment-divider {
    margin: 50px auto 20px;
    padding: 0 0 10px;
    border-bottom: 1px solid $flat-silver;
  }
}