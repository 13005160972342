@font-face {
  font-family: 'fontello';
  src: url('font/fontello.eot?18736919');
  src: url('font/fontello.eot?18736919#iefix') format('embedded-opentype'),
       url('font/fontello.woff2?18736919') format('woff2'),
       url('font/fontello.woff?18736919') format('woff'),
       url('font/fontello.ttf?18736919') format('truetype'),
       url('font/fontello.svg?18736919#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?18736919#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-mail:before { content: '\e800'; } /* 'î ' */
.icon-key:before { content: '\e801'; } /* 'î ' */
.icon-key-1:before { content: '\e802'; } /* 'î ' */
.icon-key-inv:before { content: '\e803'; } /* 'î ' */
.icon-back:before { content: '\e804'; } /* 'î ' */
.icon-home:before { content: '\e805'; } /* 'î ' */
.icon-print:before { content: '\e806'; } /* 'î ' */
.icon-doc:before { content: '\e807'; } /* 'î ' */
.icon-attach:before { content: '\e808'; } /* 'î ' */
.icon-upload:before { content: '\e809'; } /* 'î ' */
.icon-upload-outline:before { content: '\e80a'; } /* 'î ' */
.icon-floppy:before { content: '\e80b'; } /* 'î ' */
.icon-link:before { content: '\e80c'; } /* 'î ' */
.icon-down-dir:before { content: '\e80d'; } /* 'î ' */
.icon-up-dir:before { content: '\e80e'; } /* 'î ' */
.icon-left-dir:before { content: '\e80f'; } /* 'î ' */
.icon-right-dir:before { content: '\e810'; } /* 'î ' */
.icon-cloud-download:before { content: '\e811'; } /* 'î ' */
.icon-download:before { content: '\e812'; } /* 'î ' */
.icon-download-1:before { content: '\e813'; } /* 'î ' */
.icon-attention:before { content: '\e814'; } /* 'î ' */
.icon-attention-circled:before { content: '\e815'; } /* 'î ' */
.icon-info-circled:before { content: '\e816'; } /* 'î ' */
.icon-database-light:before { content: '\e817'; } /* 'î ' */
.icon-book:before { content: '\e818'; } /* 'î ' */
.icon-bookmark:before { content: '\e819'; } /* 'î ' */
.icon-book-open:before { content: '\e81a'; } /* 'î ' */
.icon-book-1:before { content: '\e81b'; } /* 'î ' */
.icon-bookmark-empty:before { content: '\f097'; } /* 'ï' */
.icon-docs:before { content: '\f0c5'; } /* 'ï' */
.icon-mail-alt:before { content: '\f0e0'; } /* 'ï ' */
.icon-upload-cloud:before { content: '\f0ee'; } /* 'ï®' */
.icon-doc-text:before { content: '\f0f6'; } /* 'ï¶' */
.icon-info:before { content: '\f129'; } /* 'ï©' */
.icon-attention-alt:before { content: '\f12a'; } /* 'ïª' */
.icon-lock-open-alt:before { content: '\f13e'; } /* 'ï¾' */
.icon-youtube-play:before { content: '\f16a'; } /* 'ïª' */
.icon-slack:before { content: '\f198'; } /* 'ï' */
.icon-database:before { content: '\f1c0'; } /* 'ï' */
.icon-file-pdf:before { content: '\f1c1'; } /* 'ï' */
.icon-file-word:before { content: '\f1c2'; } /* 'ï' */
.icon-file-excel:before { content: '\f1c3'; } /* 'ï' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* 'ï' */
.icon-file-image:before { content: '\f1c5'; } /* 'ï' */
.icon-file-archive:before { content: '\f1c6'; } /* 'ï' */
.icon-file-code:before { content: '\f1c9'; } /* 'ï' */
.icon-user-circle-o:before { content: '\f2be'; } /* 'ï¾' */